import { inject, observer } from 'mobx-react';
import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import './HintPopup.css';

@inject('AppStore')
@observer
class HintPopup extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {}

  onClose = (e) => {
    e.stopPropagation();
    this.store.hint.visible = false;
  };

  render() {
    if (!this.store.hint.visible) return <React.Fragment />;

    return (
      <div className="dependency" onClick={this.onClose}>
        <div
          className="dependency__wrapper animate__animated animate__zoomIn"
          onClick={(e) => e.stopPropagation()}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="dependency__header">
            <div className="dependecy__title">{this.store.hint.title}</div>
            <div className="dependecy__close" onClick={this.onClose}>
              <AiOutlineClose fontSize="32" />
            </div>
          </div>
          <div
            className="dependency__content"
            style={{ flex: 1, display: 'flex' }}
          >
            {this.store.hint.url && (
              <iframe
                src={this.store.hint.url}
                title={this.store.hint.title}
                style={{ flex: 1, width: '100%', border: 'none' }}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HintPopup;
