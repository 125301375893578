import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import CountUp from "react-countup";
import { appConfig } from "../config";
import { convertCurrencyLocation, sortingComparer } from "../helpers";
import styled from "styled-components";

const OptionGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

@inject("AppStore")
@observer
class EngineStep extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {}

  render() {
    const { store, step, data } = this.props;

    return (
      <div className="step-image-content">
        <div className="image" style={{ position: "relative" }}>
          {store.gallery.map(
            (item, i) =>
              store.image_mode === item.type && (
                <img
                  key={i}
                  alt="model"
                  className="animate__animated animate__fadeIn"
                  src={store.getPicture}
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    top: 0,
                  }}
                />
              )
          )}

          <div className="engine_info">
            <div className="engine_info__item">
              <div className="engine_info__title">Power</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.power.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.power.value}
                />
              </div>
            </div>
            <div className="engine_info__item">
              <div className="engine_info__title">Speed</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.speed.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.speed.value}
                />
              </div>
            </div>
          </div>

          <div
            className="image-changer"
            style={{ position: "absolute", bottom: 10, right: 10 }}
          >
            <div className="image-changer-inner">
              {store.gallery.map((item, i) => (
                <a
                  key={i}
                  className={`${
                    store.image_mode === item.type ? "active" : ""
                  }`}
                  href="/"
                  onClick={(e) => store.set_image_mode(e, item.type)}
                >
                  {i + 1}
                  {/* {item.type} */}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div
          className="options-wrapper"
          onClick={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
          onScroll={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
        >
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></div>

          <div className="option-groups">
            {step.option_groups
              .slice()
              .sort(sortingComparer)
              .map(
                (group, i) =>
                  !group.hidden && (
                    <React.Fragment key={i}>
                      {group.group_title && group.show_group_title && (
                        <div className="option-group-subtitle">
                          {group.group_title}
                        </div>
                      )}

                      <div className={`option-group ${group.type}`}>
                        {!group.hide_title && (
                          <div
                            className="option-group-title"
                            style={{
                              display: "flex",
                            }}
                          >
                            <div
                              className="selectedModel__subtitle"
                              style={{
                                fontWeight: 600,
                                paddingLeft: 35,
                                paddingTop: 5,
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  borderColor: `${store.data.color}`,
                                }}
                              >
                                {group.title}
                              </div>
                            </div>
                            <div className="brand-logo">
                              {group.brand && (
                                <img
                                  alt=""
                                  src={`${appConfig.api}${group.brand.url}`}
                                />
                              )}
                            </div>
                          </div>
                        )}

                        {group.description && (
                          <OptionGroup>
                            <div
                              className="option-desc-img"
                              style={{ marginTop: 35 }}
                            >
                              <img
                                src={`${appConfig.api}${group.picture.url}`}
                                alt=" "
                              />
                            </div>

                            <div
                              className="option-desc-content"
                              style={{ padding: 10, background: "#eee" }}
                            >
                              <pre
                                dangerouslySetInnerHTML={{
                                  __html: group.description,
                                }}
                              ></pre>
                            </div>
                          </OptionGroup>
                        )}
                        <div className={`options`} style={{ marginTop: 10 }}>
                          {group.options.map((option, j) => (
                            <div className="option-additional">
                              <div
                                className="option-content"
                                style={{
                                  padding: 10,
                                  margin: "5px 28px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  store.selectOption(option, group)
                                }
                              >
                                <div
                                  className="option-title"
                                  style={{ fontWeight: 400 }}
                                >
                                  {option.title}
                                </div>

                                <div className="option-price">
                                  {convertCurrencyLocation(
                                    option.price,
                                    this.store.location,
                                    true
                                  )}
                                  <span
                                    className={`option-selector ${
                                      option.selected && "selected"
                                    }`}
                                  >
                                    <span
                                      className="tick"
                                      style={{ background: store.data.color }}
                                    ></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default EngineStep;
