import React from "react";
import { appConfig } from "../config";
import { convertCurrencyLocation, sortingComparer } from "../helpers";
import { inject, observer } from "mobx-react";
import Hint from "./Hint";

@inject("AppStore")
@observer
class OptionWithImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${option.package ? "option-package" : ""} ${
          type === "singleImage"
            ? "option-radio-image"
            : "option-checkbox-image"
        } ${option.selected ? "option-selected" : ""}`}
        style={{ display: option.hidden ? "none" : "" }}
        onClick={() => {
          store.selectOption(option, group);
        }}
        disabled={option.package}
      >
        <div className="option-inner">
          <div className="option-image">
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              style={{
                borderColor: option.selected ? store.data.color : "#fff",
              }}
            />
          </div>
          {group.tooltip && (
            <Hint
              tooltipId={group.id}
              text={group.tooltip}
              url={group.tooltip_url}
            />
          )}
          <div className="option-title">{option[store.langify("title")]}</div>
          {option.description && (
            <div
              className="option-description"
              dangerouslySetInnerHTML={{
                __html: option[store.langify("description")],
              }}
            ></div>
          )}
          {option.package_name && (
            <div className="option-package-name">{option.package_name}</div>
          )}
          {option.price && (
            <div
              className="option-price"
              style={{ color: option.selected ? store.data.color : "#000" }}
            >
              {convertCurrencyLocation(option.price, this.store.location, true)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

@inject("AppStore")
@observer
class OptionWithoutImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${option.package ? "option-package" : ""} ${
          type === "single" ? "option-radio" : "option-checkbox"
        } ${option.selected ? "option-selected" : ""}`}
        style={{
          display: option.hidden ? "none" : "",
          borderColor: option.selected ? store.data.color : "#ccc",
        }}
        onClick={() => {
          store.selectOption(option, group);
        }}
      >
        <div className="option-inner">
          <div className="option-title">{option[store.langify("title")]}</div>
          <div className="option-description">
            <pre>{option[store.langify("description")]}</pre>
          </div>
          {option.package_name && (
            <div className="option-package-name">({option.package_name})</div>
          )}
          <div
            className="option-price"
            style={{ color: option.selected ? store.data.color : "#000" }}
          >
            {convertCurrencyLocation(option.price, this.store.location)}
          </div>
        </div>
      </div>
    );
  }
}

@observer
class GridStep extends React.Component {
  render() {
    const { step, store } = this.props;

    return (
      <div
        className="step-grid-content"
        onScroll={(e) => {
          var element = e.currentTarget;
          if (
            element.scrollHeight - element.scrollTop ===
            element.clientHeight
          ) {
            store.page.nextEnabled = true;
          }
        }}
      >
        {step.option_groups.sort(sortingComparer).map(
          (group, i) =>
            !group.hidden && (
              <div
                id={`group${group.id}`}
                className={`option-group ${group.type}`}
                key={i}
              >
                {group.title && (
                  <div
                    className="option-group-title"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <div
                        className="selectedModel__subtitle--top uc"
                        style={{ borderColor: `${store.data.color}` }}
                      >
                        {group[store.langify("title")]}
                      </div>
                    </div>
                    <div style={{ marginLeft: 30 }}>
                      {group.brand && (
                        <img
                          alt=""
                          height="35px"
                          src={`${appConfig.api}${group.brand.url}`}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div
                  className="option-group-options"
                  style={{
                    gridTemplateColumns: `repeat(${group.grid_size}, minmax(0, 1fr))`,
                  }}
                >
                  {group.options
                    .sort(sortingComparer)
                    .map((option, j) =>
                      group.type === "multiImage" ? (
                        <OptionWithImage
                          key={j}
                          type={group.type}
                          group={group}
                          option={option}
                          store={store}
                        />
                      ) : (
                        <OptionWithoutImage
                          key={j}
                          type={group.type}
                          group={group}
                          option={option}
                          store={store}
                        />
                      )
                    )}
                </div>
              </div>
            )
        )}
      </div>
    );
  }
}

export default GridStep;
