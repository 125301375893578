import React from "react";
import { InfoIcon } from "./InfoIcon";
import { inject, observer } from "mobx-react";
import Tooltip from "rc-tooltip";

// modal

@inject("AppStore")
@observer
class Hint extends React.Component {
  onOpenModal = (e) => {
    e.stopPropagation();
    this.props.AppStore.hint.url = this.props.url;
    this.props.AppStore.hint.title = this.props.text;
    this.props.AppStore.hint.visible = true;
  };

  render() {
    return (
      <span
        className="hint"
        style={{ cursor: "pointer", ...this.props.style }}
        onClick={this.onOpenModal}
      >
        {/* <span
          className={`tooltip-${this.props.tooltipId}`}
          data-tooltip-html={`<span style="text-transform: none; letter-spacing: 0; color: #000; font-size: 10px; font-family: 'Montserrat', sans-serif;">
        ${this.props.text}
      </span>`}
        >
          <InfoIcon />
        </span> */}
        {/* <Tooltip
          anchorSelect={`.tooltip-${this.props.tooltipId}`}
          style={{
            backgroundColor: "#ccc",
            color: "#000",
            fontSize: 10,
            fontFamily: "Montserrat",
          }}
        /> */}

        <Tooltip placement="left" overlay={<span>tooltip</span>}>
          <InfoIcon />
        </Tooltip>

        <div
          style={{
            display: "none",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: "#ffffffff",
          }}
        >
          <iframe
            title={this.props.url}
            src={this.props.url}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </span>
    );
  }
}

export default Hint;
